<template>
  <div>
    <v-row>
      <v-col sm="11" md="11" lg="11">
        <v-dialog v-model="commonDialog" width="80%" persistent>
          <v-card class="overflow-x-hidden overflow-y-hidden containerbox">
            <v-card-title class="subtitle-2 primary customwhite--text">
              {{ dialogHeader }}
              <v-spacer></v-spacer>
              <v-btn v-if="showCustomExport" class="mx-5 btn-style float-right" :disabled="totalRecords==0" elevation="1" outlined
                    color="secondary" @click="exportToExcel()">
                    <v-icon dark left> mdi-file-export </v-icon>Export
                  </v-btn>
              <v-btn outlined class="pa-0 mx-2" dense small title="Close" color="customwhite" @click="closeDialog">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-row >
              <v-col cols="12" sm="6" md="6" lg="12" class="px-10 ma-0 ">
                <!-- Button to add user -->
              
                <!-- Company add Comp -->
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" cols="12" class="px-10">
                <v-data-table :headers="headerArray " :items="tableItems" :class="showExport?pa-5:pa-10" :hide-default-footer="tableItems?.length<9">
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pb-3 text-xs-right pr-5 pb-5 pt-5">
                <v-btn class="ma-2 btn-style float-right" outlined color="secondary" elevation="1"
                  @click="closeDialog">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'filterComp',
  props: {
    commonDialog:Boolean,
    dialogHeader: String,
    headerArray:Array,
    tableItems:Array,
    exportObject:Object,
    showCustomExport:Boolean,
    excelFileName:String,


  },
  data() {
    return {
   
     
    }
  },
  created() {
  },
  mounted() {
  },

  methods: {
    closeDialog(){
      this.$emit("close")
  },
  exportToExcel(){
    this.$emit("exportExcel")
  },
}
}
</script>